var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"color":_vm.colors.sidebar}},[_c('v-card-text',{staticClass:"py-0 px-0"},[_c('v-card-title',{staticClass:"white--text py-2 d-flex align-center"},[(_vm.size > 960)?_c('b',{staticClass:"mt-1",staticStyle:{"font-weight":"500","font-size":"14px"}},[_vm._v("MI CARTERA "+_vm._s(_vm.saldos[_vm.$route.params.idx].simbolo)+"("+_vm._s(_vm.saldos[_vm.$route.params.idx].moneda)+")")]):_vm._e(),_c('v-btn',{staticClass:"ml-auto mb-2 mb-lg-0",attrs:{"color":_vm.colors.primary,"dark":"","block":_vm.size < 960},on:{"click":function($event){_vm.dialogAccount = true}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v("AÑADIR CUENTA ")],1),_c('v-text-field',{staticClass:"field-date ml-2",style:(_vm.size > 960 ? 'margin:0px 0 0;padding:0; max-width:212px' : ''),attrs:{"color":_vm.colors.perfil,"background-color":"#f5f5f5","single-line":"","dense":"","solo":"","flat":"","hide-details":"","append-icon":"mdi-magnify","label":"Buscar"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-divider'),_c('v-data-table',{staticClass:"horse",attrs:{"search":_vm.search,"loading":_vm.loading,"height":_vm.size < 940 ? 'calc(100vh - 324px)' : 'calc(100vh - 210px)',"fixed-header":"","headers":_vm.headers,"items":_vm.accounts,"items-per-page":10},scopedSlots:_vm._u([{key:"item.tipo",fn:function(ref){
var item = ref.item;
return [_c('b',{staticClass:"text-capitalize",staticStyle:{"font-weight":"500"}},[_vm._v(_vm._s(item.banco == null ? item.tipo : item.tipo + "|" + item.banco))])]}},{key:"item.titular",fn:function(ref){
var item = ref.item;
return [_c('b',{staticClass:"text-capitalize",staticStyle:{"font-weight":"500"}},[_vm._v(_vm._s(item.titular + "|" + (item.cedula == null ? "" : item.cedula) + "|" + (item.telefono == null ? "" : item.telefono)))])]}},{key:"item.paisnombre",fn:function(ref){
var item = ref.item;
return [_c('b',{staticClass:"text-capitalize",staticStyle:{"font-weight":"500"}},[_vm._v(_vm._s(item.paisnombre + "|" + (item.n_cuenta == null ? "" : item.n_cuenta)))])]}},{key:"item.image",fn:function(ref){
var item = ref.item;
return [_c('v-img',{attrs:{"contain":"","aspect-ratio":item.tipo == 'Transferencia Bancaria' ? 2 : 4,"width":"80px","src":item.imagen}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":"","color":"red","disabled":_vm.loading},on:{"click":function($event){return _vm.confirm(item.id)}}},[_vm._v("mdi-delete")])]}}])})],1)],1),_c('v-dialog',{attrs:{"max-width":"840","scrollable":""},model:{value:(_vm.dialogAccount),callback:function ($$v) {_vm.dialogAccount=$$v},expression:"dialogAccount"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-card-title',{staticClass:"px-0 body-1"},[_c('b',{staticClass:"color",staticStyle:{"font-weight":"500"}},[_vm._v("Selecione el tipo de cuenta")])]),_c('v-row',_vm._l((_vm.accountsTypes),function(type,idx){return _c('v-col',{key:type.id,attrs:{"cols":"12","md":"4"}},[_c('v-card',{staticClass:"mx-auto",attrs:{"outlined":""},on:{"click":function($event){return _vm.selectAccountType(type, idx)}}},[(type[("prog" + idx)])?_c('v-progress-circular',{staticClass:"d-flex mx-auto mt-11",attrs:{"indeterminate":"","color":"black","width":3}}):_c('v-img',{staticClass:"mt-4",attrs:{"contain":"","aspect-ratio":_vm.size < 940 ? 7 : 4,"src":type.imagen}}),_c('v-card-subtitle',{staticClass:"mx-auto pb-2"},[_vm._v(_vm._s(type.tipo))])],1)],1)}),1)],1),_c('v-card-actions',{staticClass:"pt-0"},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"#eb262c"},on:{"click":function($event){_vm.dialogAccount = false}}},[_vm._v("Cancelar")])],1)],1)],1),_c('v-dialog',{attrs:{"scrollable":"","max-width":_vm.selectedAccountType &&
        _vm.selectedAccountType.tipo == 'Transferencia Bancaria'
          ? 600
          : 360},model:{value:(_vm.dialogAccountForm),callback:function ($$v) {_vm.dialogAccountForm=$$v},expression:"dialogAccountForm"}},[_c('form',{attrs:{"id":"account"},on:{"submit":_vm.formAccount}},[(_vm.selectedAccountType)?_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[(_vm.edit)?_c('v-card-title',{staticClass:"d-flex pa-0 mb-2 body-1 color"},[(_vm.selectedAccountType.tipo != 'Transferencia Bancaria')?_c('b',{staticStyle:{"font-weight":"500"}},[_vm._v("Editar cuenta de "+_vm._s(_vm.selectedAccountType.tipo))]):_vm._e()]):_c('v-card-title',{staticClass:"d-flex pa-0 mb-2 body-1 color"},[(_vm.selectedAccountType.tipo != 'Transferencia Bancaria')?_c('b',{staticStyle:{"font-weight":"500"}},[_vm._v("Nueva cuenta de "+_vm._s(_vm.selectedAccountType.tipo))]):_vm._e()]),_c('v-img',{staticClass:"mb-2",attrs:{"contain":"","aspect-ratio":_vm.accountsTypes.tipo == 'Transferencia Bancaria' ? 8 : 6,"src":_vm.selectedAccountType.imagen}}),_c('v-row',[_vm._l((_vm.accountInputs.campos),function(item,idx){return [(item != 'telefono' && item != 'iban')?_c('v-col',{key:idx + 'a',attrs:{"cols":"12","lg":_vm.accountInputs.campos.length > 4
                      ? item == 'n_cuenta'
                        ? 12
                        : 6
                      : 12}},[(idx == 0 ? _vm.dialogAccountForm : true)?_c('v-text-field',{attrs:{"color":_vm.colors.perfil,"autofocus":idx == 0,"label":_vm.selectedAccountType.tipo == 'Cash App'  && item == 'email' ? '$UsuarioCashapp' 
                      : _vm.selectedAccountType.tipo == 'Zelle'  && item == 'email' ? 'email o telefono' 
                      : _vm.selectedAccountType.tipo == 'Venmo'  && item == 'email' ? '@usuariovenmo' : _vm.label(item),"dense":"","outlined":"","flat":"","background-color":"blue-grey lighten-5","hide-details":"","type":item == 'email' ? 'text' : 'text'},model:{value:(_vm.inputs[item]),callback:function ($$v) {_vm.$set(_vm.inputs, item, $$v)},expression:"inputs[item]"}}):_vm._e()],1):_vm._e(),(item == 'iban')?_c('v-col',{key:idx + 'c',attrs:{"cols":"12","lg":_vm.accountInputs.campos.length > 4
                      ? item == 'iban'
                        ? 12
                        : 6
                      : 12}},[(idx == 0 ? _vm.dialogAccountForm : true)?_c('v-text-field',{attrs:{"color":_vm.colors.perfil,"label":_vm.$route.params.id == 2
                        ? 'Numero de Ruta (Routing))'
                        : 'Tipo de Cuenta (Ahorro, Corriente, Saving ...)',"dense":"","outlined":"","flat":"","background-color":"blue-grey lighten-5","hide-details":""},model:{value:(_vm.inputs[item]),callback:function ($$v) {_vm.$set(_vm.inputs, item, $$v)},expression:"inputs[item]"}}):_vm._e()],1):_vm._e(),(item == 'telefono')?_c('v-col',{key:idx + 'b',attrs:{"cols":"12","lg":_vm.accountInputs.campos.length > 4
                      ? item == 'n_cuenta'
                        ? 12
                        : 6
                      : 12}},[_c('vue-tel-input',{ref:"tel",refInFor:true,staticClass:"phone",attrs:{"autofocus":true,"required":true,"preferred-countries":['ve', 'pe', 'co'],"placeholder":"Teléfono"},on:{"input":_vm.onInput},model:{value:(_vm.inputs[item]),callback:function ($$v) {_vm.$set(_vm.inputs, item, $$v)},expression:"inputs[item]"}})],1):_vm._e()]})],2),(_vm.selectedAccountType.tipo == 'Transferencia Bancaria')?_c('v-footer',[_c('ul',{staticClass:"py-2 indigo--text"},[_c('li',[_vm._v(" Debe ingresar el nombre del titular exactamente como aparece en su cuenta. ")]),_c('li',[_vm._v(" Verifique que los datos ingresados sean los correctos, para evitar demoras al momento de realizar la transferencia ")])])]):_vm._e()],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"#eb262c","disabled":_vm.loading,"text":""},on:{"click":function($event){_vm.dialogAccountForm = false}}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"color":"#0eb3ef","loading":_vm.loading,"disabled":_vm.loading,"text":"","type":"submit"}},[_vm._v("Guardar")])],1)],1):_vm._e()],1)]),_c('Confirm',{attrs:{"title":"¿ELIMINAR CUENTA?","text":"La cuenta se eliminara, esta acción no se puede revertir.","loading":_vm.loading,"open":_vm.dialog},on:{"ok":_vm.deleteAcc,"close":function($event){_vm.dialog = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }