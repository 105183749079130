<template>
  <v-app-bar 
    v-if="($route.name != 'Evenbet' && $route.name != 'Nacionales' && $route.name != 'Tycg' && $route.name != 'Virtuales' && $route.path != '/gametype' && $route.path != '/casino' && $route.path != '/inh' && $route.path != '/casinoindex' && $route.path != '/macuare' && $route.path != '/sport' && $route.path != '/sportach' && $route.path != '/sportjugadas') || size > 960"
    app
    :color="colors.header"
    :dark="marca == 'Betgana Races'"
    class="mx-0 appbar-shadow"
    :style="
      (routeName == 'Bet' || routeName == 'Channels') &&
      size < 960 &&
      colors.gradientHeader
        ? `background-image: linear-gradient(40deg, ${colors.gradientHeader[0]} 0%, ${colors.gradientHeader[1]} 100%);`
        : ''
    "
    
    :class="$route.name == 'Juego' && size < 960 ? 'd-none' : ''"
  >
    <template
      v-if="
        size < 960 &&
          routeName != 'Bet' &&
          routeName != 'Index' &&
          routeName != 'Channels' &&
          routeName != 'Promociones' &&
          routeName != 'Lista de juegos' &&
          routeName != 'Juego' &&
          routeName != 'Inh'
      "
    >
      <v-app-bar-nav-icon @click="$emit('navIcon')"></v-app-bar-nav-icon>
    </template>
    <v-btn v-if="routeName == 'Juego'" icon @click="$router.replace('/casino')">
      <v-icon>mdi-arrow-left</v-icon>
    </v-btn>
    <!-- <template

v-if="$store.state.windowSize.x > 960 && $route.name =='Index' || $store.state.windowSize.x > 960"

		>-->
    <template
      v-if="size > 960 || routeName == 'Index' || routeName == 'Promociones'"
    >
      <v-toolbar-title class="d-flex align-center" style="cursor: pointer">
        <v-img v-if="size > 490" :src="logo" :max-width="logoW" contain></v-img>
        <span
          v-if="logoName == 'false' ? false : true"
          class="t-title ml-2 hidden-sm-and-down text-uppercase"
          >{{ marca }}</span
        >
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <template v-if="token == null">
        <!-- <v-img
          class="mr-2"
          @click="$emit('login')"
          src="https://res.cloudinary.com/dy7vqkcwp/image/upload/v1686607066/botones/login_xcgizj.svg"
          max-width="110"
          contain
        ></v-img>
        <v-img
          v-if="registro != 0"
          class="mr-2"
          @click="$emit('register')"
          src="https://res.cloudinary.com/dy7vqkcwp/image/upload/v1686607066/botones/register_mig4hw.svg"
          max-width="110"
          contain
        ></v-img> -->
        <v-btn
          class="mr-2"
          depressed
          color="primary"
          elevation="0"
          outlined
          @click="$emit('login')"
          ><v-icon>mdi-account</v-icon> Iniciar sesión</v-btn
        >
        <v-btn
          dark
          depressed
          :color="colors.error"
          v-if="registro != 0"
          @click="$emit('register')"
          >REGISTRATE <v-icon>mdi-plus</v-icon></v-btn
        >
      </template>
      <template v-else>
        <template v-if="$route.name != 'Juego'">
          <!-- <v-btn
						v-if="(idsb != 13 || idsb != '13') && $route.path != '/gametype'"
						rounded
						depressed
						:color="colors.primary"
						dark
						@click.stop="$router.push('/bet')"
					>
						<span :class="marca == 'Betgana Races' ? 'black--text' : ''">Jugar</span>
					</v-btn> -->
          <template
            v-if="(idsb == 13 || idsb == 8) && $route.path != '/gametype'"
          >
            <!-- <v-img
              v-if="
                $route.path != '/marcas' &&
                  ($store.state.idsb == 13 || $store.state.idsb == 8)
              "
              @click.stop="$router.push('/marcas')"
              src="https://res.cloudinary.com/dy7vqkcwp/image/upload/v1686607066/botones/marcas_imqrq6.svg"
              max-width="100"
              contain
            ></v-img>

            <v-img
              v-if="$store.state.idsb == 13 || $store.state.idsb == 8"
              @click.stop="$router.push('/gamep/livecasino/101')"
              src="https://res.cloudinary.com/dy7vqkcwp/image/upload/v1686607066/botones/casino_ivwhsw.svg"
              max-width="100"
              contain
            ></v-img>

            <v-img
              v-if="
                this.$store.state.pais_user == 'Venezuela' &&
                  this.$store.state.idmoneda < 3 &&
                  $route.path != '/inh'
              "
              @click.stop="$router.push('/inh')"
              src="https://res.cloudinary.com/dy7vqkcwp/image/upload/v1686607066/botones/hipico_wwepsv.svg"
              max-width="100"
              contain
            ></v-img>

            <v-img
              v-else-if="$route.path != '/bet' && $route.path != '/inh'"
              @click.stop="$router.push('/bet')"
              src="https://res.cloudinary.com/dy7vqkcwp/image/upload/v1686607066/botones/hipico_wwepsv.svg"
              max-width="100"
              contain
            ></v-img>

            <v-img
              v-if="$route.path != '/casino'"
              @click.stop="$router.push('/casino')"
              src="https://res.cloudinary.com/dy7vqkcwp/image/upload/v1686607066/botones/maquinitas_sbdcej.svg"
              max-width="100"
              contain
            ></v-img>

            <v-img
              v-if="
                $route.path != '/sport' &&
                  ($store.state.idsb == 13 || $store.state.idsb == 8)
              "
              @click.stop="$router.push('/sport')"
              src="https://res.cloudinary.com/dy7vqkcwp/image/upload/v1686607068/botones/parley_cjotgt.svg"
              max-width="100"
              contain
            ></v-img> -->
             <!-- <v-img
              v-if="
                $route.path != '/concurso' &&
                  ($store.state.idsb == 13 || $store.state.idsb == 8)
              "
              @click.stop="$router.push('/concurso')"
              src="https://apitr.xyz/dominio/tr/dubaicup24.png"
              max-width="180"
              contain
            ></v-img> 
             -->
             <v-btn
              class="pa-2"
              depressed
              text
              :color="colors.primary"
              dark
              @click.stop="$router.push('/gametype')"
            >
              <span class="text-center black--text"
                ><v-icon>mdi-home</v-icon> Home</span
              >
            </v-btn>

             <v-btn v-if="this.$store.state.idmoneda == 1"
              class="pa-2"
              depressed
              text
              :color="colors.primary"
              dark
              @click.stop="$router.push('/nacionales')"
            >
              <span class="text-center black--text"
                ><v-icon>mdi-horse-variant-fast</v-icon> Nacionales</span
              >
            </v-btn>
            
            <v-btn
              
              text
              
              
              small
              depressed
              :color="colors.primary"
              dark
              @click.stop="$router.push('/macuare')"
            >
              <span class="text-center black--text"
                ><v-icon>mdi-horse</v-icon> Macuare</span
              >
            </v-btn>
            
            <!-- <v-btn
              
              text
              
              
              small
              depressed
              :color="colors.primary"
              dark
              @click.stop="$router.push('/marcas')"
            >
              <span class="text-center black--text"
                ><v-icon>mdi-horseshoe</v-icon> Marcas</span
              >
            </v-btn> -->

            <v-btn
              class="pa-2"
              depressed
              text
              :color="colors.primary"
              dark
              @click.stop="$router.push('/gamep/livecasino/101')"
            >
              <span class="text-center black--text"
                ><v-icon>mdi-poker-chip</v-icon> Casino en Vivo</span
              >
            </v-btn>

            <v-btn
              v-if="
                this.$store.state.pais_user == 'Venezuela' &&
                  this.$store.state.idmoneda < 3 &&
                  $route.path != '/inh'
              "
              class="pa-2"
              text
              depressed
              small
              :color="colors.primary"
              dark
              @click.stop="$router.push('/inh')"
            >
              <span class="text-center black--text"
                ><v-icon>mdi-horse-variant</v-icon> INTERNACIONALES</span
              >
            </v-btn>

            <v-btn
              v-else-if="$route.path != '/bet' && $route.path != '/inh'"
              text
              class="pa-2"
              small
              depressed
              :color="colors.primary"
              dark
              @click.stop="$router.push('/bet')"
            >
              <span class="text-center black--text"
                ><v-icon>mdi-horse-variant</v-icon>INTERNACIONALES</span
              >
            </v-btn>

            <v-btn
              v-if="
                $route.path != '/sport' &&
                  ($store.state.idsb == 13 || $store.state.idsb == 8)
              "
              text
              class="pa-2"
              small
              depressed
              :color="colors.primary"
              dark
              @click.stop="$router.push('/sport')"
            >
              <span class="text-center black--text"
                ><v-icon>mdi-baseball-diamond</v-icon>Parley</span
              >
            </v-btn>

            <v-btn
              v-if="$route.path != '/casino'"
              text
              class="pa-2"
              small
              depressed
              :color="colors.primary"
              dark
              :class="$route.path == '/casino' ? 'mr-2' : ''"
              @click.stop="$router.push('/casino')"
            >
              <span class="text-center black--text"
                ><v-icon>mdi-slot-machine</v-icon> Maquinitas</span
              >
            </v-btn>
          </template>
        </template>
        <template v-if="$route.path != '/gametype'">
          <v-btn
            v-if="false"
            rounded
            small
            depressed
            :color="colors.primary"
            dark
            to="/gametype"
          >
            <span :class="marca == 'Betgana Races' ? 'black--text' : ''"
              >JUEGOS</span
            >
          </v-btn>
          <template v-if="$route.name == 'Juego'">
            <!-- <v-img
              v-if="$store.state.idsb == 13 || $store.state.idsb == 8"
              @click.stop="$router.push('/gamep/livecasino/101')"
              src="https://res.cloudinary.com/dy7vqkcwp/image/upload/v1686607066/botones/casino_ivwhsw.svg"
              max-width="100"
              contain
            ></v-img>

            <v-img
              v-if="
                $route.path != '/marcas' &&
                  ($store.state.idsb == 13 || $store.state.idsb == 8)
              "
              @click.stop="$router.push('/marcas')"
              src="https://res.cloudinary.com/dy7vqkcwp/image/upload/v1686607066/botones/marcas_imqrq6.svg"
              max-width="100"
              contain
            ></v-img>

            <v-img
              v-if="
                this.$store.state.pais_user == 'Venezuela' &&
                  this.$store.state.idmoneda < 3 &&
                  $route.path != '/inh'
              "
              @click.stop="$router.push('/inh')"
              src="https://res.cloudinary.com/dy7vqkcwp/image/upload/v1686607066/botones/hipico_wwepsv.svg"
              max-width="100"
              contain
            ></v-img>

            <v-img
              v-else-if="$route.path != '/bet' && $route.path != '/inh'"
              @click.stop="$router.push('/bet')"
              src="https://res.cloudinary.com/dy7vqkcwp/image/upload/v1686607066/botones/hipico_wwepsv.svg"
              max-width="100"
              contain
            ></v-img>

            <v-img
              v-if="$route.path != '/casino'"
              @click.stop="$router.push('/casino')"
              src="https://res.cloudinary.com/dy7vqkcwp/image/upload/v1686607066/botones/maquinitas_sbdcej.svg"
              max-width="100"
              contain
            ></v-img>

            <v-img
              v-if="
                $route.path != '/sport' &&
                  ($store.state.idsb == 13 || $store.state.idsb == 8)
              "
              @click.stop="$router.push('/sport')"
              src="https://res.cloudinary.com/dy7vqkcwp/image/upload/v1686607068/botones/parley_cjotgt.svg"
              max-width="100"
              contain
            ></v-img> -->

            <!-- <v-btn
              v-if="
                $route.path != '/marcas' &&
                  ($store.state.idsb == 13 || $store.state.idsb == 8)
              "
              class="mr-2"
              text
              small
              depressed
              :color="colors.primary"
              dark
              @click.stop="$router.push('/marcas')"
            >
              <span class="text-center black--text"
                ><v-icon>mdi-horseshoe</v-icon> Marcas</span
              >
            </v-btn> -->

            <v-btn
              depressed
              text
              :color="colors.primary"
              dark
              @click.stop="$router.push('/gamep/livecasino/101')"
            >
              <span class="text-center black--text"
                ><v-icon>mdi-poker-chip</v-icon> Casino en Vivo</span
              >
            </v-btn>

            <v-btn
              v-if="
                this.$store.state.pais_user == 'Venezuela' &&
                  this.$store.state.idmoneda < 3 &&
                  $route.path != '/inh'
              "
              text
              depressed
              small
              :color="colors.primary"
              dark
              @click.stop="$router.push('/bet')"
            >
              <span class="text-center black--text"
                ><v-icon>mdi-horse-variant</v-icon> Hipico INH</span
              >
            </v-btn>

            <v-btn
              v-else-if="$route.path != '/bet' && $route.path != '/inh'"
              text
              small
              depressed
              :color="colors.primary"
              dark
              @click.stop="$router.push('/bet')"
            >
              <span class="text-center black--text"
                ><v-icon>mdi-horse-variant</v-icon>Hipico</span
              >
            </v-btn>

            <v-btn
              v-if="
                $route.path != '/sport' &&
                  ($store.state.idsb == 13 || $store.state.idsb == 8)
              "
              text
              small
              depressed
              :color="colors.primary"
              dark
              @click.stop="$router.push('/sport')"
            >
              <span class="text-center black--text"
                ><v-icon>mdi-baseball-diamond</v-icon>Parley</span
              >
            </v-btn>

            <v-btn
              v-if="$route.path != '/casino'"
              text
              small
              depressed
              :color="colors.primary"
              dark
              :class="$route.path == '/casino' ? 'mr-2' : ''"
              @click.stop="$router.push('/casino')"
            >
              <span class="text-center black--text"
                ><v-icon>mdi-slot-machine</v-icon> Maquinitas</span
              >
            </v-btn>
          </template>
          <template v-if="$route.name != 'Juego'">
            <template v-if="$route.path != '/casino'">
              <v-btn
                v-if="
                  currentBalance.toLocaleString() > '1.00' &&
                    $route.path == '/bet' &&
                    size > 2300
                "
                :color="colors.perfil"
                dark
                depressed
                small
                @click="dialogC = true"
                rounded
              >
                <span :class="marca == 'Betgana Races' ? 'black--text' : ''"
                  >Canales en vivo</span
                >
              </v-btn>

              <!-- <v-img
                class="d-flex align-center"
                v-if="$route.path == '/bet'"
                @click="dialogProgramas = true"
                src="https://res.cloudinary.com/dy7vqkcwp/image/upload/v1686610062/botones/btnblueline_oldueq.svg"
                max-width="100"
                contain
                ><span
                  style="line-height: 1.4; font-weight: 500"
                  class="d-block ml-2 "
                  >Programas</span
                ></v-img
              > -->
              <v-btn
                v-if="$route.path == '/bet'"
                outlined
                depressed
                height="45"
                dark
                small
                :color="colors.primary"
                @click="dialogProgramas = true"
              >
                <span
                  :class="
                    marca == 'Betgana Races' ? 'black--text' : 'black--text'
                  "
                  >Programas</span
                >
              </v-btn>
              <!--<v-btn rounded depressed class="mx-2" :color="colors.reglas" @click="dialogRules = true">
								<span :class="marca == 'Betgana Races' ? 'black--text' : ''">REGLAS</span>
							</v-btn>-->
            </template>
          </template>
          <!-- <div v-if="$store.state.premios">
            <v-badge
              v-if="$store.state.premios.jugadas != '0'"
              class="animate mr-4"
              bordered
              :color="colors.error"
              :content="$store.state.premios.jugadas"
              :value="$store.state.premios.jugadas"
              overlap
            >
              <v-btn rounded text color="error" depressed to="/rewards">
                <v-icon class="mr-1" size="24" color="red">mdi-ticket</v-icon>
                <b>{{ $store.state.premios.premio }} UND</b>
              </v-btn>
            </v-badge>
          </div> -->
          <div v-if="$route.name != 'Juego'">
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-btn
                  :loading="loadingSaldo"
                  color="transparent"
                  dark
                  height="45"
                  v-on="on"
                  depressed
                  class="px-1 ml-2"
                  style="border-radius: 8px;background: linear-gradient(0deg,var(--select-0) 0%,var(--select-1) 100%) !important;"
                >
                  <div class="d-flex align-center">
                    <v-icon
                      class="ml-1"
                      style="text-transform: capitalize"
                      dark
                      size="24"
                      >{{ simbolo }}</v-icon
                    >
                    <div class="d-block ml-2">
                      <span
                        class="d-flex"
                        style="line-height: 1.4; font-weight: 500"
                        >{{ currentBalance.toLocaleString() }} UND</span
                      >
                      <small
                        class="d-block tex"
                        style="font-weight: 500; line-height: 1.2; font-size: 10px"
                        >Bono: {{ currentBono.toLocaleString() }} UND</small
                      >
                    </div>
                    <v-icon class="ml-1" dark>mdi-menu-down</v-icon>
                  </div>
                </v-btn>
              </template>
              <v-list dense class="text-left">
                <v-subheader>CARTERAS</v-subheader>
                <v-list-item-group
                  v-model="saldoSelected"
                  :color="colors.gradientBarra[0]"
                >
                  <v-list-item
                    two-line
                    v-for="(saldo, idx) in saldos"
                    :key="idx"
                    @click="selectSaldo(saldo.idmoneda)"
                  >
                    <v-list-item-content>
                      <v-list-item-title
                        >{{ saldo.simbolo }} ({{
                          saldo.moneda
                        }})</v-list-item-title
                      >
                      <v-list-item-subtitle
                        >{{ saldo.balance }} UND</v-list-item-subtitle
                      >
                      <v-list-item-subtitle>
                        <small>BONO: ({{ saldo.bono }} UND)</small>
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                        SALDO: {{ saldo.simbolo
                        }}{{
                          parseFloat(saldo.cambio) * parseFloat(saldo.balance)
                        }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
          </div>
        </template>
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-btn
              rounded
              height="45"
              :color="colors.perfil"
              dark
              v-on="on"
              depressed
              class="ml-2 px-1 "
              style="border-top-right-radius: 4px"
            >
              <div class="d-flex align-center">
                <v-icon dark size="32">mdi-account-circle</v-icon>
                <div class="d-block ml-2">
                  <span
                    class="d-flex"
                    style="line-height: 1.4; font-weight: 500"
                  >
                    {{ $store.state.nombre.split(" ")[0] }}
                  </span>
                  <!-- <span

class="d-block"

style=";font-weight:500;line-height: 1.2;"

									>{{ $store.state.nombre.split(' ')[0] }}</span>-->
                </div>
                <v-icon class="ml-1" dark>mdi-menu-down</v-icon>
              </div>
            </v-btn>
          </template>
          <v-list dense class="text-left">
            <v-list-item
              @click.stop="$router.push('/profile')"
            >
              <v-list-item-title>PERFIL</v-list-item-title>
            </v-list-item>
            <v-list-item
              @click="dialogRules = true"
              v-if="$route.path != '/gametype'"
            >
              <v-list-item-title>REGLAS y LIMITES HIPICOS</v-list-item-title>
            </v-list-item>
            <v-list-item @click="logout()">
              <v-list-item-title>CERRAR SESIÓN</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </template>
    <template
      v-else-if="
        selectedEvent &&
          selectedEvent.idhipodromo &&
          ($route.name == 'Bet' || $route.name == 'Channels')
      "
    >
      <!-- <v-btn

@click="changeEventMobile(-1)"

depressed

fab

dark

x-small

color="#00afef"

:disabled="events[0].idhipodromo == selectedEvent.idhipodromo"

>

<v-icon dark>mdi-arrow-left</v-icon>

			</v-btn>-->
      <!-- <div style="width:calc(100vw - 96px)"> -->
      <div>
        <v-list dense nav two-line flat color="transparent" class="px-0">
          <v-list-item
            style="min-height: 54px !important"
            @click="dialogEvents = true"
          >
            <v-list-item-avatar class="my-0" size="34">
              <v-img
                v-if="selectedEvent.tipo == 'HIPODROMO'"
                src="../assets/img/hipo.svg"
              ></v-img>
              <v-img
                v-if="selectedEvent.tipo == 'GALGODROMO'"
                src="../assets/img/galgos.svg"
              ></v-img>
              <v-img
                v-if="selectedEvent.tipo == 'CARRETA'"
                src="../assets/img/carretas.svg"
              ></v-img>
            </v-list-item-avatar>
            <v-list-item-content class="py-0">
              <v-list-item-title>
                <b style="font-size: 14px; color: #fff">{{
                  selectedEvent.nombre
                }}</b>
              </v-list-item-title>
              <v-list-item-subtitle>
                <small class="mr-2">
                  <b style="color: #fff"
                    >CARRERA {{ selectedEvent.idcarrera }}</b
                  >
                </small>
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <div class="d-flex align-center">
                <v-chip
                  class="px-2"
                  small
                  label
                  :color="colors.primary"
                  v-if="selectedEvent.estatus == 'ABIERTA'"
                >
                  <small>
                    <b
                      :class="
                        marca == 'Betgana Races' ? 'black--text' : 'white--text'
                      "
                      >{{ selectedEvent.estatus }}</b
                    >
                  </small>
                </v-chip>
                <v-chip
                  class="px-2"
                  small
                  label
                  :color="colors.warning"
                  v-if="selectedEvent.estatus == 'CORRIENDO'"
                >
                  <small>
                    <b>{{ selectedEvent.estatus }}</b>
                  </small>
                </v-chip>
                <v-chip
                  class="px-2"
                  small
                  dark
                  label
                  :color="colors.error"
                  v-if="selectedEvent.estatus == 'CERRADA'"
                >
                  <small>
                    <b>{{ selectedEvent.estatus }}</b>
                  </small>
                </v-chip>
                <v-chip
                  class="ml-2"
                  small
                  :color="colors.primary"
                  v-if="selectedEvent.tipo != 'GALGODROMO'"
                >
                  <b
                    :class="
                      marca == 'Betgana Races' ? 'black--text' : 'white--text'
                    "
                  >
                    {{ selectedEvent.nivel }}
                  </b>
                </v-chip>
                <v-icon dark class="ml-1">mdi-menu-down</v-icon>
              </div>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </div>
      <!-- <v-btn

depressed

fab

:dark="events[events.length - 1].idhipodromo != selectedEvent.idhipodromo"

x-small

color="#0c6fc2"

@click="changeEventMobile()"

:disabled="events[events.length - 1].idhipodromo == selectedEvent.idhipodromo"

>

<v-icon dark>mdi-arrow-right</v-icon>

			</v-btn>-->
    </template>
    <template
      v-if="
        size < 960 &&
          $route.name != 'Index' &&
          $route.name != 'Channels' &&
          $route.name != 'Bet' &&
          $route.name != 'Promociones'
      "
    >
      <v-toolbar-title v-if="routeName != 'Juego'">{{
        $route.name
      }}</v-toolbar-title>
      <v-toolbar-title v-if="routeName == 'Juego'">
        {{ $route.params.name.replace("-", " ") }}
      </v-toolbar-title>

      <v-spacer></v-spacer>
      <v-icon v-if="routeName == 'Juegos'" @click="$store.state.filter = true"
        >mdi-filter-variant</v-icon
      >
      <div
        style="width: 39%"
        v-if="$route.path != '/casino' && $route.path != '/gametype'"
      >
        <v-select
          v-if="routeName != 'Juego'"
          :color="colors.primary"
          label="Mi cartera"
          single-line
          dense
          solo
          flat
          background-color="transparent"
          hide-details
          v-model="idmoneda"
          required
          item-text="moneda"
          item-value="idmoneda"
          type="currency"
          :items="saldos"
        >
          <template slot="selection" slot-scope="data">
            <!-- HTML that describe how select should render selected items -->
            {{ data.item.simbolo }} ({{ data.item.moneda }})
          </template>
          <template slot="item" slot-scope="data">
            <!-- HTML that describe how select should render items when the select is open -->
            {{ data.item.simbolo }} ({{ data.item.moneda }})
            {{
              data.item.pais == "ECUADOR"
                ? "- ECUADOR"
                : data.item.pais == "EEUU"
                ? "- EEUU"
                : ""
            }}
          </template>
        </v-select>
      </div>
      <!-- <v-btn text icon @click="$store.state.dialogRe = true">

<v-icon>mdi-cash-plus</v-icon>

</v-btn>

<v-btn text icon @click="$store.state.dialogPay = true">

<v-icon>mdi-cash-minus</v-icon>

			</v-btn>-->
    </template>
    <!-- <template v-if="$store.state.windowSize.x < 960 && $route.name == 'Index'">

<v-toolbar-title class="d-flex align-center" style="cursor:pointer">

<v-img src="../assets/img/logo.svg" max-width="80" contain></v-img>

<span class="t-title ml-2 hidden-sm-and-down">TRACK & RACES</span>

</v-toolbar-title>

<v-spacer></v-spacer>

<v-btn

v-if="$store.state.token == null"

text

color="#2463b6"

@click="dialogLogin=true;"

>Iniciar sesión</v-btn>

<v-btn

v-if="$store.state.token == null"

dark

depressed

color="#eb262c"

@click="dialogRegister=true;"

>REGISTRATE</v-btn>

		</template>-->
  </v-app-bar>
</template>

<script>
import axios from "axios";
import { mapActions } from "vuex";
import { mapState } from "vuex";
//import misCuentas from "../components/MisCuentas.vue"

export default {
  data() {
    return {
      saldoSelected: 0,
      d: false,
    };
  },
  created() {
    if (this.$route.path != "/") {
      this.getSaldo();
    }
  },
  mounted() {
    this.$store.state.pais_user = sessionStorage.getItem("pais_user");
  },
  computed: {
    ...mapState({
      saldos: "saldos",
      loadingSaldo: "loadingSaldo",
      currentBalance: "currentBalance",
      currentBono: "currentBono",
      simbolo: "simbolo",
    }),
    idsb() {
      return this.$store.state.idsb;
    },
    idmoneda: {
      get() {
        return this.$store.state.idmoneda;
      },
      set(e) {
        this.$store.state.idmoneda = e;
      },
    },

    dialogC: {
      get() {
        return this.$store.state.dialogChannel;
      },
      set(v) {
        this.$store.state.dialogChannel = v;
      },
    },
    dialogRules: {
      get() {
        return this.$store.state.dialogRules;
      },
      set(v) {
        this.$store.state.dialogRules = v;
      },
    },
    dialogProgramas: {
      get() {
        return this.$store.state.dialogProgramas;
      },
      set(v) {
        this.$store.state.dialogProgramas = v;
      },
    },
    registro() {
      return this.$store.state.registro;
    },
    mode() {
      return this.$store.state.mode;
    },
    logo() {
      return this.$store.state.logo;
    },
    logoName() {
      return this.$store.state.logoName;
    },
    logoW() {
      return this.$store.state.logoW;
    },
    marca() {
      return this.$store.state.marca;
    },
    size() {
      return this.$store.state.windowSize.x;
    },
    token() {
      return this.$store.state.token;
    },
    routeName() {
      return this.$route.name;
    },
    selectedEvent() {
      return this.$store.state.selectedEvent;
    },
    events() {
      return this.$store.state.events;
    },
    dialogEvents: {
      get() {
        return this.$store.state.dialogEvents;
      },
      set(value) {
        this.$store.state.dialogEvents = value;
      },
    },
    colors() {
      return this.$store.state.colors;
    },
  },
  methods: {
    ...mapActions(["getSaldo", "changeLoadingSaldo"]),
    changeWallet(id) {
      axios({
        method: "post",
        url: `/cambiar_cartera`,
        data: {
          idmoneda: id,
        },
      }).then(async () => {
        await this.getSaldo();
        this.saldoSelected = 0;
      });
    },
    selectSaldo(id) {
      this.changeLoadingSaldo(true);
      this.changeWallet(id);
    },
    logout() {
      axios({
        method: "post",
        url: `https://apitr.xyz/onlineapi/api/logout`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          accept: "application/json",
        },
      }).then((response) => {
        if (response.data.res) {
          this.$store.state.token = null;
          this.$router.replace("/");
          localStorage.removeItem("token");
          sessionStorage.removeItem("token");
        }
      });
    },
  },
};
</script>

<style>
.appbar-shadow {
  background-color: rgba(255, 255, 255, 0.9) !important;
  box-shadow: 1px 1px 6px -4px rgba(0, 0, 0, 0.3) !important;
}
</style>
