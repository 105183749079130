var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.colors.gradient)?_c('v-card',{staticClass:"eventos",attrs:{"color":_vm.colors.sidebar,"flat":"","dark":""}},[_c('div',{staticClass:"appbar-shadow",style:(("background: linear-gradient(0deg, " + (_vm.colors.gradient[1]) + " 0%, " + (_vm.colors.gradient[0]) + " 100%);height:46px;border-radius:5px"))},[_c('v-tabs',{staticClass:"ma-0",attrs:{"slider-color":"#ff2128","slider-size":"2","grow":"","background-color":"transparent","height":"46px"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_c('v-img',{attrs:{"src":require("../../assets/img/hipo.svg"),"aspect-ratio":"1","max-width":"30px"}})],1),_c('v-tab',[_c('v-img',{attrs:{"src":require("../../assets/img/galgos.svg"),"aspect-ratio":"1","max-width":"30px"}})],1),_c('v-tab',[_c('v-img',{attrs:{"src":require("../../assets/img/carretas.svg"),"aspect-ratio":"1","max-width":"30px"}})],1)],1)],1),_c('v-card-text',{staticClass:"pt-0 px-0"},[_c('v-tabs-items',{staticStyle:{"background":"transparent"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('v-list',{staticClass:"event-list",attrs:{"nav":"","rounded":"","dark":"","two-line":"","dense":"","flat":"","color":"transparent"}},[(_vm.hipodromos.length != 0)?[_c('v-list-item-group',{attrs:{"no-action":"","color":"white"},model:{value:(_vm.item),callback:function ($$v) {_vm.item=$$v},expression:"item"}},[_vm._l((_vm.hipodromos),function(item,idx){return [(item.estatus != 'CERRADA')?_c('v-list-item',{key:idx,staticClass:"event-item",on:{"click":function($event){return _vm.changeEvent(item, true, idx, 0)}}},[_c('v-list-item-avatar',{attrs:{"size":"32"}},[(item.canal != '' && item.estatus != 'CERRADA')?_c('v-tooltip',{attrs:{"color":"red darken-1","dark":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(
                              _vm.$store.state.currentBalance.toLocaleString() <
                                '0.00'
                            )?_c('div',[(
                                item.canal != '' && item.estatus != 'CERRADA'
                              )?_c('v-btn',_vm._b({staticStyle:{"z-index":"4","border":"3px solid #eb262c","background":"white"},attrs:{"color":"white","icon":""},on:{"mouseenter":function($event){_vm.shown = true},"mouseleave":function($event){_vm.shown = false},"click":function($event){_vm.shown = true}}},'v-btn',attrs,false),[_c('v-icon',{attrs:{"color":"#eb262c"}},[_vm._v("mdi-play")])],1):_vm._e()],1):_c('div',[(
                                item.canal != '' && item.estatus != 'CERRADA'
                              )?_c('v-btn',{staticStyle:{"z-index":"4","border":"3px solid #eb262c","background":"white"},attrs:{"color":"white","icon":""},on:{"mouseenter":function($event){_vm.hover = true},"mouseleave":function($event){_vm.hover = false},"click":function($event){return _vm.openCanal(item.canal)}}},[_c('v-icon',{attrs:{"color":"#eb262c"}},[_vm._v("mdi-play")])],1):_vm._e()],1)]}}],null,true),model:{value:(_vm.shown),callback:function ($$v) {_vm.shown=$$v},expression:"shown"}},[_c('span',[_vm._v("Por favor recargue su saldo para ver los canales en vivo")])]):(item.tipo == 'HIPODROMO')?_c('v-img',{attrs:{"src":require("../../assets/img/hipo.svg")}}):(item.tipo == 'GALGODROMO')?_c('v-img',{attrs:{"src":require("../../assets/img/galgos.svg")}}):(item.tipo == 'CARRETA')?_c('v-img',{attrs:{"src":require("../../assets/img/carretas.svg")}}):_vm._e()],1),_c('v-list-item-content',{staticClass:"py-0"},[_c('v-list-item-title',{staticClass:"mb-0"},[_c('b',{staticClass:"font-weight-medium caption"},[_vm._v(_vm._s(item.nombre))])]),_c('v-list-item-subtitle',{staticStyle:{"line-height":"0.7rem"}},[_c('small',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"font-weight-medium"},[_vm._v("CARRERA "+_vm._s(item.idcarrera))]),(item.mtp != 0)?_c('span',{staticClass:"ml-1"},[_vm._v("-")]):_vm._e(),(
                              item.mtp !== '' &&
                                item.estatus != 'CORRIENDO' &&
                                item.estatus != 'CERRADA'
                            )?_c('span',{staticClass:"white--text ml-1 font-weight-medium",class:item.mtp == 0 ? 'animate-mtp' : ''},[_vm._v("[ "+_vm._s(item.mtp)+" MTP ]")]):_vm._e()])])],1),_c('v-list-item-action',[_c('div',{staticClass:"d-flex align-center"},[_c('v-chip',{class:item.estatus == 'CORRIENDO' ? 'black--text' : '',staticStyle:{"padding":"10px 10px"},attrs:{"x-small":"","dark":"","color":_vm.color(item.estatus)}},[_c('b',{staticClass:"font-weight-medium",style:(_vm.marca == 'Betgana Races' &&
                              item.estatus != 'CERRADA'
                                ? 'color:black'
                                : '')},[_c('small',[_vm._v(_vm._s(item.estatus))])])])],1)])],1):_vm._e()]})],2),_c('v-list-item-group',{attrs:{"no-action":"","color":"white"},model:{value:(_vm.selectedItem),callback:function ($$v) {_vm.selectedItem=$$v},expression:"selectedItem"}},_vm._l((_vm.items),function(item,i){return _c('v-list-item',{key:i,staticClass:"event-item"},[_c('v-list-item-icon',{staticClass:"mt-3"},[_c('v-icon',{domProps:{"textContent":_vm._s(item.icon)}})],1),_c('v-list-item-content',{staticClass:"py-0",on:{"click":function($event){$event.stopPropagation();return _vm.$router.push(item.ruta)}}},[_c('v-list-item-title',{staticClass:"mt-0  mb-0",domProps:{"textContent":_vm._s(item.text)}})],1),_c('v-list-item-action',[_c('div',{staticClass:"d-flex align-center"},[_c('v-chip',{staticStyle:{"padding":"10px 10px"},attrs:{"x-small":"","dark":"","color":"green"}},[(item.text!='NACIONALES')?_c('b',[_vm._v(" 24 / 7 ")]):_c('b',[_vm._v(" VEN ")])])],1)])],1)}),1),_c('v-list-item-group',{attrs:{"no-action":"","color":"white"},model:{value:(_vm.item),callback:function ($$v) {_vm.item=$$v},expression:"item"}},[_vm._l((_vm.hipodromos),function(item,idx){return [(item.estatus == 'CERRADA')?_c('v-list-item',{key:idx,staticClass:"event-item",on:{"click":function($event){return _vm.changeEvent(item, true, idx, 0)}}},[_c('v-list-item-avatar',{attrs:{"size":"32"}},[(item.canal != '' && item.estatus != 'CERRADA')?_c('v-tooltip',{attrs:{"color":"red darken-1","dark":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
                                var attrs = ref.attrs;
return [(
                              _vm.$store.state.currentBalance.toLocaleString() <
                                '1.00'
                            )?_c('div',[(
                                item.canal != '' && item.estatus != 'CERRADA'
                              )?_c('v-btn',_vm._b({staticStyle:{"z-index":"4","border":"3px solid #eb262c","background":"white"},attrs:{"color":"white","icon":""},on:{"mouseenter":function($event){_vm.shown = true},"mouseleave":function($event){_vm.shown = false},"click":function($event){_vm.shown = true}}},'v-btn',attrs,false),[_c('v-icon',{attrs:{"color":"#eb262c"}},[_vm._v("mdi-play")])],1):_vm._e()],1):_c('div',[(
                                item.canal != '' && item.estatus != 'CERRADA'
                              )?_c('v-btn',{staticStyle:{"z-index":"4","border":"3px solid #eb262c","background":"white"},attrs:{"color":"white","icon":""},on:{"mouseenter":function($event){_vm.hover = true},"mouseleave":function($event){_vm.hover = false},"click":function($event){return _vm.openCanal(item.canal)}}},[_c('v-icon',{attrs:{"color":"#eb262c"}},[_vm._v("mdi-play")])],1):_vm._e()],1)]}}],null,true),model:{value:(_vm.shown),callback:function ($$v) {_vm.shown=$$v},expression:"shown"}},[_c('span',[_vm._v("Por favor recargue su saldo para ver los canales en vivo")])]):(item.tipo == 'HIPODROMO')?_c('v-img',{attrs:{"src":require("../../assets/img/hipo.svg")}}):(item.tipo == 'GALGODROMO')?_c('v-img',{attrs:{"src":require("../../assets/img/galgos.svg")}}):(item.tipo == 'CARRETA')?_c('v-img',{attrs:{"src":require("../../assets/img/carretas.svg")}}):_vm._e()],1),_c('v-list-item-content',{staticClass:"py-0"},[_c('v-list-item-title',{staticClass:"mb-0"},[_c('b',{staticClass:"font-weight-medium caption"},[_vm._v(_vm._s(item.nombre))])]),_c('v-list-item-subtitle',{staticStyle:{"line-height":"0.7rem"}},[_c('small',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"font-weight-medium"},[_vm._v("CARRERA "+_vm._s(item.idcarrera))]),(item.mtp != 0)?_c('span',{staticClass:"ml-1"},[_vm._v("-")]):_vm._e(),(
                              item.mtp !== '' &&
                                item.estatus != 'CORRIENDO' &&
                                item.estatus != 'CERRADA'
                            )?_c('span',{staticClass:"white--text ml-1 font-weight-medium",class:item.mtp == 0 ? 'animate-mtp' : ''},[_vm._v("[ "+_vm._s(item.mtp)+" MTP ]")]):_vm._e()])])],1),_c('v-list-item-action',[_c('div',{staticClass:"d-flex align-center"},[_c('v-chip',{class:item.estatus == 'CORRIENDO' ? 'black--text' : '',staticStyle:{"padding":"10px 10px"},attrs:{"x-small":"","dark":"","color":_vm.color(item.estatus)}},[_c('b',{staticClass:"font-weight-medium",style:(_vm.marca == 'Betgana Races' &&
                              item.estatus != 'CERRADA'
                                ? 'color:black'
                                : '')},[_c('small',[_vm._v(_vm._s(item.estatus))])])])],1)])],1):_vm._e()]})],2)]:[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"mx-auto"},[_c('h3',[_vm._v("No hay eventos disponibles")])])])]],2)],1),_c('v-tab-item',[_c('v-list',{staticClass:"event-list",attrs:{"nav":"","rounded":"","dark":"","two-line":"","dense":"","color":"transparent"}},[(_vm.galgos.length != 0)?[_c('v-list-item-group',{attrs:{"no-action":"","color":"white"},model:{value:(_vm.item1),callback:function ($$v) {_vm.item1=$$v},expression:"item1"}},[_vm._l((_vm.galgos),function(item,idx){return [_c('v-list-item',{key:idx,staticClass:"event-item",on:{"click":function($event){return _vm.changeEvent(item, true, idx, 1)}}},[_c('v-list-item-avatar',{attrs:{"size":"32"}},[(item.canal != '' && item.estatus != 'CERRADA')?_c('v-btn',{staticStyle:{"z-index":"4","border":"3px solid #eb262c","background":"white"},attrs:{"color":"white","icon":""},on:{"mouseenter":function($event){_vm.hover = true},"mouseleave":function($event){_vm.hover = false},"click":function($event){return _vm.openCanal(item.canal)}}},[_c('v-icon',{attrs:{"color":"#eb262c"}},[_vm._v("mdi-play")])],1):(item.tipo == 'HIPODROMO')?_c('v-img',{attrs:{"src":require("../../assets/img/hipo.svg")}}):(item.tipo == 'GALGODROMO')?_c('v-img',{attrs:{"src":require("../../assets/img/galgos.svg")}}):(item.tipo == 'CARRETA')?_c('v-img',{attrs:{"src":require("../../assets/img/carretas.svg")}}):_vm._e()],1),_c('v-list-item-content',{staticClass:"py-0"},[_c('v-list-item-title',{staticClass:"mb-0"},[_c('b',{staticClass:"font-weight-medium caption"},[_vm._v(_vm._s(item.nombre))])]),_c('v-list-item-subtitle',{staticStyle:{"line-height":"0.7rem"}},[_c('small',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"font-weight-medium"},[_vm._v("CARRERA "+_vm._s(item.idcarrera))]),(item.mtp != 0)?_c('span',{staticClass:"ml-1"},[_vm._v("-")]):_vm._e(),(
                              item.mtp !== '' &&
                                item.estatus != 'CORRIENDO' &&
                                item.estatus != 'CERRADA'
                            )?_c('span',{staticClass:"white--text ml-1 font-weight-medium",class:item.mtp == 0 ? 'animate-mtp' : ''},[_vm._v("[ "+_vm._s(item.mtp)+" MTP ]")]):_vm._e()])])],1),_c('v-list-item-action',[_c('div',{staticClass:"d-flex align-center"},[_c('v-chip',{class:item.estatus == 'CORRIENDO' ? 'black--text' : '',staticStyle:{"padding":"10px 10px"},attrs:{"x-small":"","dark":"","color":_vm.color(item.estatus)}},[_c('b',{staticClass:"font-weight-medium",style:(_vm.marca == 'Betgana Races' &&
                              item.estatus != 'CERRADA'
                                ? 'color:black'
                                : '')},[_c('small',[_vm._v(_vm._s(item.estatus))])])])],1)])],1)]})],2)]:[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"mx-auto"},[_c('h3',[_vm._v("No hay eventos disponibles")])])])]],2)],1),_c('v-tab-item',[_c('v-list',{staticClass:"event-list",attrs:{"nav":"","rounded":"","dark":"","two-line":"","dense":"","color":"transparent"}},[(_vm.carretas.length != 0)?[_c('v-list-item-group',{attrs:{"no-action":"","color":"white"},model:{value:(_vm.item2),callback:function ($$v) {_vm.item2=$$v},expression:"item2"}},[_vm._l((_vm.carretas),function(item,idx){return [_c('v-list-item',{key:idx,staticClass:"event-item",on:{"click":function($event){return _vm.changeEvent(item, true, idx, 2)}}},[_c('v-list-item-avatar',{attrs:{"size":"32"}},[(item.canal != '' && item.estatus != 'CERRADA')?_c('v-btn',{staticStyle:{"z-index":"4","border":"3px solid #eb262c","background":"white"},attrs:{"color":"white","icon":""},on:{"mouseenter":function($event){_vm.hover = true},"mouseleave":function($event){_vm.hover = false},"click":function($event){return _vm.openCanal(item.canal)}}},[_c('v-icon',{attrs:{"color":"#eb262c"}},[_vm._v("mdi-play")])],1):(item.tipo == 'HIPODROMO')?_c('v-img',{attrs:{"src":require("../../assets/img/hipo.svg")}}):(item.tipo == 'GALGODROMO')?_c('v-img',{attrs:{"src":require("../../assets/img/galgos.svg")}}):(item.tipo == 'CARRETA')?_c('v-img',{attrs:{"src":require("../../assets/img/carretas.svg")}}):_vm._e()],1),_c('v-list-item-content',{staticClass:"py-0"},[_c('v-list-item-title',{staticClass:"mb-0"},[_c('b',{staticClass:"font-weight-medium caption"},[_vm._v(_vm._s(item.nombre))])]),_c('v-list-item-subtitle',{staticStyle:{"line-height":"0.7rem"}},[_c('small',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"font-weight-medium"},[_vm._v("CARRERA "+_vm._s(item.idcarrera))]),(item.mtp != 0 || item.mtp != '')?_c('span',{staticClass:"ml-1"},[_vm._v("-")]):_vm._e(),(
                              item.mtp !== '' &&
                                item.estatus != 'CORRIENDO' &&
                                item.estatus != 'CERRADA'
                            )?_c('span',{staticClass:"white--text ml-1 font-weight-medium",class:item.mtp == 0 ? 'animate-mtp' : ''},[_vm._v("[ "+_vm._s(item.mtp)+" MTP ]")]):_vm._e()])])],1),_c('v-list-item-action',[_c('div',{staticClass:"d-flex align-center"},[_c('v-chip',{class:item.estatus == 'CORRIENDO' ? 'black--text' : '',staticStyle:{"padding":"10px 10px"},attrs:{"x-small":"","dark":"","color":_vm.color(item.estatus)}},[_c('b',{staticClass:"font-weight-medium",style:(_vm.marca == 'Betgana Races' &&
                              item.estatus != 'CERRADA'
                                ? 'color:black'
                                : '')},[_c('small',[_vm._v(_vm._s(item.estatus))])])])],1)])],1)]})],2)]:[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"mx-auto"},[_c('h3',[_vm._v("No hay eventos disponibles")])])])]],2)],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }