<template>
  <div>
    <v-overlay :value="overlay" style="height: 100vh">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <header>
      <nav class="d-flex">
        <ul class="mr-auto">
          <li v-show="idsb == 1">
            <a href="#"
              ><span class="text-center white--text"
                ><v-icon color="#fff">mdi-chat</v-icon></span
              ></a
            >
          </li>
          <li>
            <a href="#"
              ><span
                class="text-center white--text text-body-2"
                @click.stop="$router.push('/sportjugadas')"
                ><v-icon class="text-body-2" color="#fff">mdi-history</v-icon> Historial</span
              ></a
            >
          </li>
        </ul>
        <ul class="ml-auto">
          <span class="pa-3 mt-4 text-body-2"
            >Saldo: {{ mon }} {{ (currentBalance * multiplicador).toLocaleString('es', {
                                minimumFractionDigits: 2,
                                }) }}</span
          >
        </ul>
      </nav>
    </header>
    <iframe
      :src="gameUrl"
      :style="
        size < 960
          ? 'width: 100%; height: calc('+this.screenHeight*0.84+'px)'
          : 'width: 100%; height: calc(100vh - 95px)'
      "
      frameborder="0"
    ></iframe>
    <MisCuentas />
  </div>
</template>

<script>
import axios from "axios";
import { mapActions } from "vuex";
import MisCuentas from "../components/MisCuentas.vue";
import misCuentas from "../components/MisCuentas.vue";
export default {
  data() {
    return {
      gameUrl: null,
      overlay: true,
      isMobile: 0,
      misCuentas,
      multiplicador: 1,
      mon: null,
      screenHeight: 0
    };
  },
  watch: {
    idmoneda() {
      this.overlay = true;
      this.getGame();
      //this.showChat();
    },
  },
  beforeDestroy() {
    if (typeof window === "undefined") return;
    window.removeEventListener("resize", this.onResize, { passive: true });
  },
  mounted() {
    this.screenHeight = window.innerHeight;
    this.isMobile = window.innerWidth < 600 ? 1 : 0;
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
    axios({
      method: "post",
      url: `details`,
    }).then((response) => {
      this.$store.state.nombre = response.data.success.name;
    });
    this.getSaldo();
    if (document.getElementById("fc_frame")) {
      document.getElementById("fc_frame").style.display = "none";
    }
    this.idpos = sessionStorage.getItem("idpos");
    this.getGame();
  },
  updated() {
    if (document.getElementById("fc_frame")) {
      document.getElementById("fc_frame").style.display = "none";
    }
  },
  methods: {
    ...mapActions(["getSaldo"]),
    onResize() {
      this.isMobile = window.innerWidth < 600 ? 1 : 0;
    },
    getGame() {
      this.mon = "";
      this.multiplicador = 1;
      if (this.$store.state.idmoneda == 1) {
        this.mon = "VES";
        this.multiplicador = 42;
      } 
      else if (this.$store.state.idmoneda == 2) this.mon = "USD";
      else if (this.$store.state.idmoneda == 3) this.mon = "PEN";
      else if (this.$store.state.idmoneda == 7) this.mon = "COP";
      else if (this.$store.state.idmoneda == 8) this.mon = "CLP";
      axios({
        method: "post",
        url: `https://trackandraces.com/onlineapi/api/sportlogin`,
        data: {
          moneda: this.mon,
        },
      }).then((response) => {
        console.log(response);
        const url = response.data.Link;
        this.gameUrl = url;
        //alert("Las unidades de su Cartera seran convertidas en Bolivares para realizar las Jugadas");
        this.overlay = false;
      });
    },
  },
  computed: {
    size() {
      return this.$store.state.windowSize.x;
    },
    moneda() {
      return this.$store.state.moneda;
    },
    idmoneda() {
      return this.$store.state.idmoneda;
    },
    idsb() {
      return this.$store.state.idsb;
    },
    currentBalance() {
      return this.$store.state.currentBalance;
    },
    idpos: {
      get() {
        return this.$store.state.idpos;
      },
      set(e) {
        this.$store.state.idpos = e;
      },
    },
  },
  components: { MisCuentas },
};
</script>

<style>
header {
  background-color: #fa22229a;
  border-radius: 5px;
  color: #fff;
  padding: 1px;
  margin-left: 6px;
  margin-right: 6px;
  margin-bottom: 3px;
}

nav ul {
  list-style: none;
  padding: 3px;
  margin: 0;
}

nav li {
  display: inline; /* Hace que los ítems del menú estén en una línea horizontal */
  margin-right: 20px; /* Espacio entre los ítems del menú */
}

nav a {
  text-decoration: none;
  color: #fff !important;
  font-size: 16px;
}

nav a:hover {
  font-size: 17px;
  color: #000 !important;
}
</style>
