import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueTelInput from "vue-tel-input";
import VueGtag from "vue-gtag";
import axios from "axios";
import VueFreshchat from "vue-freshchat";

(async function() {

 /* localStorage.openpages = Date.now();
  var onLocalStorageEvent = function(e) {
    if (e.key == "openpages") {
      // Emit that you're already available.
      localStorage.page_available = Date.now();
    }
    if (e.key == "page_available") {
      alert("Track and Races ya se encuentra activa en otra pestaña");
      window.location.href = "https://google.com";
    }
  };
  window.addEventListener("storage", onLocalStorageEvent, false);*/
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/sw.js')
      .then(registration => {
        console.log('Service Worker registrado con éxito:', registration);
      })
      .catch(error => {
        console.error('Error al registrar el Service Worker:', error);
      });
  }
  
  // eslint-disable-next-line no-unused-vars
  
  




  axios.defaults.baseURL = "https://trackandraces.com/onlineapi/api/";

  let dominio;
  let id;

  let domName = document.location.hostname;
  if (domName != "localhost") {
    dominio = domName;
  } else {
    dominio = "trackandraces.com";
  }
  // console.log(dominio);

  id = await axios.post("https://trackandraces.com/onlineapi/api/dominio", {
    dominio: dominio,
    analytics: true,
  });
  Vue.use(
    VueGtag,
    {
      config: {
        id: id.data.id_analytics,
      },
    },
    router
  );
  //console.log(id.data.id_analytics);

  Vue.use(VueTelInput);
  if (dominio == "tar.la" || dominio == "www.tar.la" || dominio == "trackandraces.com" || dominio == "www.trackandraces.com" || dominio == "localhost:8080") {
    Vue.use(VueFreshchat, { appToken: "ac46045e-087b-4989-b190-4ac0ad998f86" });
  }

  Vue.config.productionTip = true;

  
  new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
  }).$mount("#app");
})();
